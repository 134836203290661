import * as TP from '../types';

import { gql } from '@apollo/client';
import { KeyValueInfoFragmentDoc } from '../fragments/keyValueInfo';
import { FacilityAddressBriefFragmentDoc } from '../fragments/FacilityAddressBrief';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FacilityMfItemV2Fragment = (
  { readonly __typename?: 'FacilityV2' }
  & Pick<TP.FacilityV2, 'code' | 'id' | 'name' | 'phoneNumber' | 'status'>
  & { readonly facilityType?: TP.Maybe<(
    { readonly __typename?: 'KeyValue' }
    & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
  )>, readonly mainAddress?: TP.Maybe<(
    { readonly __typename?: 'FacilityAddress' }
    & Pick<TP.FacilityAddress, 'id' | 'addressTypeId' | 'city' | 'country' | 'main' | 'postalCode' | 'state' | 'street1' | 'street2' | 'county' | 'riskInfoSource' | 'thirdPartyId' | 'isVerified'>
    & { readonly addressType?: TP.Maybe<(
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
    )>, readonly addressTypes?: TP.Maybe<ReadonlyArray<(
      { readonly __typename?: 'KeyValue' }
      & Pick<TP.KeyValue, 'id'>
    )>>, readonly geopoint: (
      { readonly __typename?: 'Geopoint' }
      & Pick<TP.Geopoint, 'lon' | 'lat'>
    ), readonly geopointNullable?: TP.Maybe<(
      { readonly __typename?: 'Geopoint' }
      & Pick<TP.Geopoint, 'lat' | 'lon'>
    )>, readonly customGeopoint?: TP.Maybe<(
      { readonly __typename?: 'Geopoint' }
      & Pick<TP.Geopoint, 'lat' | 'lon'>
    )> }
  )> }
);

export type FacilitiesForMasterfindV2QueryVariables = TP.Exact<{
  filter?: TP.InputMaybe<TP.FacilitiesFilterV2>;
  first?: TP.InputMaybe<TP.Scalars['Int']>;
  last?: TP.InputMaybe<TP.Scalars['Int']>;
  before?: TP.InputMaybe<TP.Scalars['String']>;
  after?: TP.InputMaybe<TP.Scalars['String']>;
}>;


export type FacilitiesForMasterfindV2Query = (
  { readonly __typename?: 'Query' }
  & { readonly allFacilitiesV2: (
    { readonly __typename?: 'FacilityConnectionV2' }
    & { readonly edges: ReadonlyArray<(
      { readonly __typename?: 'FacilityEdgeV2' }
      & { readonly node: (
        { readonly __typename?: 'FacilityV2' }
        & Pick<TP.FacilityV2, 'code' | 'id' | 'name' | 'phoneNumber' | 'status'>
        & { readonly facilityType?: TP.Maybe<(
          { readonly __typename?: 'KeyValue' }
          & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
        )>, readonly mainAddress?: TP.Maybe<(
          { readonly __typename?: 'FacilityAddress' }
          & Pick<TP.FacilityAddress, 'id' | 'addressTypeId' | 'city' | 'country' | 'main' | 'postalCode' | 'state' | 'street1' | 'street2' | 'county' | 'riskInfoSource' | 'thirdPartyId' | 'isVerified'>
          & { readonly addressType?: TP.Maybe<(
            { readonly __typename?: 'KeyValue' }
            & Pick<TP.KeyValue, 'active' | 'id' | 'metadataJson' | 'name'>
          )>, readonly addressTypes?: TP.Maybe<ReadonlyArray<(
            { readonly __typename?: 'KeyValue' }
            & Pick<TP.KeyValue, 'id'>
          )>>, readonly geopoint: (
            { readonly __typename?: 'Geopoint' }
            & Pick<TP.Geopoint, 'lon' | 'lat'>
          ), readonly geopointNullable?: TP.Maybe<(
            { readonly __typename?: 'Geopoint' }
            & Pick<TP.Geopoint, 'lat' | 'lon'>
          )>, readonly customGeopoint?: TP.Maybe<(
            { readonly __typename?: 'Geopoint' }
            & Pick<TP.Geopoint, 'lat' | 'lon'>
          )> }
        )> }
      ) }
    )> }
  ) }
);

export const FacilityMfItemV2FragmentDoc = gql`
    fragment FacilityMFItemV2 on FacilityV2 {
  code
  facilityType {
    ...KeyValueInfo
  }
  id
  mainAddress {
    ...FacilityAddressBrief
  }
  name
  phoneNumber
  status
}
    ${KeyValueInfoFragmentDoc}
${FacilityAddressBriefFragmentDoc}`;
export const FacilitiesForMasterfindV2Document = gql`
    query facilitiesForMasterfindV2($filter: FacilitiesFilterV2, $first: Int, $last: Int, $before: String, $after: String) {
  allFacilitiesV2(
    filter: $filter
    first: $first
    last: $last
    before: $before
    after: $after
  ) {
    edges {
      node {
        ...FacilityMFItemV2
      }
    }
  }
}
    ${FacilityMfItemV2FragmentDoc}`;

/**
 * __useFacilitiesForMasterfindV2Query__
 *
 * To run a query within a React component, call `useFacilitiesForMasterfindV2Query` and pass it any options that fit your needs.
 * When your component renders, `useFacilitiesForMasterfindV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFacilitiesForMasterfindV2Query({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useFacilitiesForMasterfindV2Query(baseOptions?: Apollo.QueryHookOptions<FacilitiesForMasterfindV2Query, FacilitiesForMasterfindV2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FacilitiesForMasterfindV2Query, FacilitiesForMasterfindV2QueryVariables>(FacilitiesForMasterfindV2Document, options);
      }
export function useFacilitiesForMasterfindV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FacilitiesForMasterfindV2Query, FacilitiesForMasterfindV2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FacilitiesForMasterfindV2Query, FacilitiesForMasterfindV2QueryVariables>(FacilitiesForMasterfindV2Document, options);
        }
export type FacilitiesForMasterfindV2QueryHookResult = ReturnType<typeof useFacilitiesForMasterfindV2Query>;
export type FacilitiesForMasterfindV2LazyQueryHookResult = ReturnType<typeof useFacilitiesForMasterfindV2LazyQuery>;
export type FacilitiesForMasterfindV2QueryResult = Apollo.QueryResult<FacilitiesForMasterfindV2Query, FacilitiesForMasterfindV2QueryVariables>;