import { SIDEBAR_QUERY_PARAM } from '@app/Sidebar/constants';
import { TableId } from '@components/Table';
import { TablePreferencesSettings } from '@components/Table/util/customizeTable';
import { KEY_USER_PREFERENCES } from '@utils/constants';
import { getLocalStorage, setLocalStorage } from '@utils/localStorage';
import { isEqual, noop, pickBy } from 'lodash-es';
import { FC, createContext, useContext, useMemo, useState } from 'react';

export interface AvailableRoutesReferencePreference {
  id: string;
  label: string;
  referenceType: string;
}

export interface MPRBReferencePreference {
  id: string;
  label: string;
  referenceType: string;
}

export interface UserPreferencesState {
  mostRecentCarrier?: string;
  sidebarOpen?: boolean;
  tablePreferences?: Record<TableId, TablePreferencesSettings[]>;
  selectedRoutePreference?: string;
  fullstoryDebug?: boolean;
  preferMilitaryTime?: boolean;
  newWindowOptOut?: boolean;
  availableRoutesReferenceVisibility?: AvailableRoutesReferencePreference[];
  mprbReferenceVisibility?: MPRBReferencePreference[];
  /** Used internally for PR previews to point to dev or test */
  prDomain?: string;
  theme?: 'dark' | 'light';
}

type SetPrefsArg = Partial<UserPreferencesState>;

interface UserPreferencesActions {
  setPrefs: (userPreferences: SetPrefsArg) => void;
  removePrefs: () => void;
}

const rawDefaults: UserPreferencesState = {
  mostRecentCarrier: '',
  tablePreferences: {} as Record<TableId, TablePreferencesSettings[]>,
  sidebarOpen: true,
  selectedRoutePreference: '',
  fullstoryDebug: false,
  preferMilitaryTime: false,
  theme: 'light',
  availableRoutesReferenceVisibility: [],
  mprbReferenceVisibility: [],
};

export const getInitialPrefsFromStorage = (): UserPreferencesState => {
  try {
    const fromStorage =
      getLocalStorage<UserPreferencesState>(KEY_USER_PREFERENCES) ?? {};
    const amendedFromStorage: UserPreferencesState = pickBy(
      {
        ...fromStorage,
        sidebarOpen: SIDEBAR_QUERY_PARAM ?? fromStorage.sidebarOpen ?? null,
        theme: fromStorage.theme ?? 'light',
      },
      (v) => v !== null
    );
    if (!isEqual(fromStorage, amendedFromStorage)) {
      setLocalStorage(KEY_USER_PREFERENCES, amendedFromStorage);
    }
    return {
      ...rawDefaults,
      ...amendedFromStorage,
    };
  } catch {
    return { ...rawDefaults };
  }
};

function useUserPrefs(): [UserPreferencesState, UserPreferencesActions] {
  const [statePrefs, setStoredValue] = useState<UserPreferencesState>(
    (): UserPreferencesState => getInitialPrefsFromStorage()
  );

  const setPrefs = (rawValues: SetPrefsArg): void => {
    try {
      const prevFromStorage =
        getLocalStorage<UserPreferencesState>(KEY_USER_PREFERENCES);
      const finalValues = {
        ...prevFromStorage,
        // Exclude sidebar from cross-tab sync - it should remain more stable per tab - but still allow it to be set for tab reload or new tab.
        sidebarOpen: statePrefs.sidebarOpen,
        ...rawValues,
      };
      setStoredValue(finalValues);
      setLocalStorage(KEY_USER_PREFERENCES, finalValues);
    } catch {
      // If user is in private mode or has storage restriction
      // localStorage can throw. Also JSON.stringify can throw.
    }
  };

  const removePrefs = (): void => {
    setPrefs(rawDefaults);
  };

  return [
    statePrefs,
    {
      setPrefs,
      removePrefs,
    },
  ];
}

interface PrefsContextType
  extends UserPreferencesState,
    UserPreferencesActions {}

const defaultPrefsCtx: PrefsContextType = {
  ...getInitialPrefsFromStorage(),
  setPrefs: noop,
  removePrefs: noop,
};

const PrefsContext = createContext<PrefsContextType>(defaultPrefsCtx);

const UserPreferences: FC = ({ children }) => {
  const [prefs, actions] = useUserPrefs();

  const value = useMemo(() => {
    return { ...prefs, ...actions };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prefs]);

  return (
    <PrefsContext.Provider value={value}>{children}</PrefsContext.Provider>
  );
};

const useUserPreferences = (): PrefsContextType => {
  return useContext(PrefsContext);
};

export { UserPreferences, useUserPreferences };
