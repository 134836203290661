import { userDataFromStorage } from '@components/AuthContext';
import { BUILD_VERSION, IS_PR_PREVIEW, USE_LD } from '@utils/constants';
import { LDContext } from 'launchdarkly-js-client-sdk';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { FC, ReactNode } from 'react';

const FakeLDProvider: FC = ({ children }) => <>{children}</>;

const anonmyousUser = {
  anonymous: true,
  key: 'anonymous',
};

interface initLDProps {
  launchDarklyKey: string;
  appName?: string;
  env?: string;
}

export const initLD = async (
  props: initLDProps
): Promise<FC<{ children: ReactNode }>> => {
  const { slim } = await import(
    `@generated/flags/slim${
      props.appName === 'connect-carrier-frontend' ? '-connect-carrier' : ''
    }.ts`
  );
  /* istanbul ignore next: we do not use real LD in tests */
  if (USE_LD) {
    let context: LDContext = {
      kind: 'user',
      ...(userDataFromStorage || anonmyousUser),
    };
    if (props.appName) {
      context = {
        kind: 'multi',
        user: {
          ...(userDataFromStorage || anonmyousUser),
        },
        component: {
          key: props.appName,
        },
        env: {
          key: props.env,
        },
      };
    }
    return asyncWithLDProvider({
      clientSideID: props.launchDarklyKey,
      context,
      options: {
        bootstrap: 'localStorage',
        application: {
          id: props.appName ?? 'mastermind-frontend',
          version: BUILD_VERSION ?? IS_PR_PREVIEW ? 'pull' : 'unknown',
        },
      },
      reactOptions: {
        // https://docs.launchdarkly.com/docs/react-sdk-reference#section-flag-keys
        useCamelCaseFlagKeys: false,
      },
      flags: slim,
    });
  }
  return Promise.resolve(FakeLDProvider);
};
