import { TabObj } from '@components/Tabs';
import {
  createContext,
  FC,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

interface TabPreferencesContextManager {
  getTabPreferencesById: (tabId: string, tabs: fixMe[]) => void;
  tabPreferences?: Record<string, Array<TabObj<string>>>;
  handleSaveTabPreferences: (
    tabId: string,
    tabs: Array<TabObj<string>>
  ) => void;
}

const defaultSettings = {
  isVisible: true,
  default: false,
};

const TabPreferencesContext = createContext<TabPreferencesContextManager>({
  getTabPreferencesById: () => {},
  handleSaveTabPreferences: () => {},
  tabPreferences: {},
});

interface PlaceHolder {
  id: string;
  tabGroupId: string;
  createdBy: string;
  tabs: Array<TabObj<string>>;
}

interface PayloadType {
  [key: string]: PlaceHolder;
}

function mergeTabsById(
  encodedTabs: Array<TabObj<string>>,
  savedTabPreferences: Array<TabObj<string>>
): Array<TabObj<string>> {
  return encodedTabs.map((encodedTab) => {
    const tabFoundInSavedPreferences = savedTabPreferences.find(
      (item) => item.id === encodedTab.id
    );
    return {
      ...encodedTab,
      ...(tabFoundInSavedPreferences || defaultSettings),
    };
  });
}

const getTabPreferencesByIdMock = (tabId: string): PayloadType => {
  const payload: Record<string, fixMe> = {
    'main-page-top-area': {
      id: '8675309',
      tabGroupId: 'main-page-top-area',
      createdBy: 'peterman',
      tabs: [
        {
          id: 'operations',
          default: false,
          isVisible: true,
          order: 0,
        },
        {
          id: 'tasks',
          default: false,
          isVisible: true,
          order: 1,
        },
        {
          id: 'portfolio',
          default: false,
          isVisible: false,
          order: 2,
        },
        {
          id: 'my-matches',
          default: false,
          isVisible: true,
          order: 3,
        },
        {
          id: 'commissions',
          default: false,
          isVisible: false,
          order: 4,
        },
        {
          id: 'dashboard/',
          default: false,
          isVisible: true,
          order: 5,
        },
        {
          id: 'reports/',
          default: false,
          isVisible: true,
          order: 6,
        },
      ],
    },
  };
  return (
    payload[tabId] || {
      id: tabId,
      tabGroupId: tabId,
      createdBy: '',
      tabs: [],
    }
  );
};

export const TabPreferencesProvider: FC<anyOk> = ({ children }) => {
  const [tabPreferences, setTabPreferences] = useState<
    Record<string, Array<TabObj<string>>>
  >({});

  const getTabPreferencesById = useCallback(
    (tabId: string, tabs: Array<TabObj<string>>): void => {
      const savedTabPreferences = getTabPreferencesByIdMock(tabId);
      const mergedTabs = mergeTabsById(
        tabs,
        savedTabPreferences?.tabs as fixMe
      );
      setTabPreferences((prefs: Record<string, Array<TabObj<string>>>) => ({
        ...prefs,
        [tabId]: mergedTabs,
      }));
    },
    []
  );

  const handleSaveTabPreferences = useCallback(
    (tabId: string, tabs: Array<TabObj<string>>): void => {
      setTabPreferences((prefs: Record<string, Array<TabObj<string>>>) => ({
        ...prefs,
        [tabId]: tabs,
      }));
    },
    []
  ); // Empty dependency array since it only uses setTabPreferences which is stable

  const value: TabPreferencesContextManager = useMemo(() => {
    return {
      getTabPreferencesById,
      tabPreferences,
      handleSaveTabPreferences,
    };
  }, [getTabPreferencesById, tabPreferences, handleSaveTabPreferences]);

  return (
    <TabPreferencesContext.Provider value={value}>
      {children}
    </TabPreferencesContext.Provider>
  );
};

export const useTabPreferences = (): TabPreferencesContextManager => {
  const context = useContext(TabPreferencesContext);
  if (!context) {
    throw new Error(
      'useTabPreferences must be used within a TabPreferencesProvider'
    );
  }
  return context;
};
